<template>
    <div class="d-flex flex-column h-100">
        <el-row :gutter="20">
            <el-col :lg="4" :xl="4" class="d-flex flex-column">
                <label for="range" class="mb-1">{{ $t('delivery.filter_for_date') }}</label>
                <el-date-picker v-model="filters.range" :default-time="clearFiltersTask ? [] : ['00:00:00', '23:59:59']" type="daterange" range-separator="" start-placeholder="Start date" end-placeholder="End date" id="range" size="small" class="w-100"/>
            </el-col>
            <el-col :lg="4" :xl="4" class="d-flex flex-column">
                <label for="search" class="mb-1">{{ $t('delivery.key_words') }}</label>
                <el-input placeholder="Please input" v-model="filters.keywords" size="small" id="search" @keydown.native="handleKeyDown"/>
            </el-col>
            <el-col :lg="4" :xl="4">
                <app-selection @change="(data)=>{filters.employee_id = data}" title="delivery.filter_by_delivery" :options="employeesOptions" key-value="id" id="employee" />
            </el-col>
            <el-col :lg="4" :xl="4" class="d-flex flex-column">
                <app-selection @change="(data)=>{filters.sales_location_id = data}" title="delivery.filter_by_branch" :options="storesOptions" key-value="codplace" id="branch" />
            </el-col>
            <el-col :lg="4" :xl="4" class="d-flex flex-column">
                <app-selection :reload="newStatus" @change="(data)=>{filters.status = data}" title="delivery.filter_by_state" :options="statusOptions" key-value="id" id="status" :default="filters.status"/>
            </el-col>
            <el-col :lg="4" :xl="4" class="d-flex h-100 justify-content-end align-items-end">
                <el-popover placement="right" width="300" trigger="click">
                    <div class="d-flex flex-column">
                        <app-selection @change="(data)=>{filters.document_types = data}" title="tracking.filter_by_document_type" :options="typesOptions" key-value="id" id="types" />
                        <hr>
                        <app-selection @change="(data)=>{filters.types = data}" title="tracking.filter_by_type" :options="typesOptionsTask" key-value="id" id="types" />
                        <hr>
                        <el-checkbox v-model="filters.has_incidences" class="mt-2" value="1" unchecked-value="0">
                            {{$t('delivery.only_tasks_with_incidences')}}
                        </el-checkbox>
                        <hr>
                        <el-radio-group v-model="filters.order_by">
                            <el-radio :label="'created_at'">Fecha de creacion</el-radio>
                            <el-radio :label="'date'">Fecha</el-radio>
                        </el-radio-group>
                        <hr>
                        <el-radio-group v-model="filters.order_by_type">
                            <el-radio :label="'desc'">Desc</el-radio>
                            <el-radio :label="'asc'">Asc</el-radio>
                        </el-radio-group>
                    </div>
                    <el-button slot="reference" size="small">More filters</el-button>
                </el-popover>
                <el-button size="small" type="warning" class="ms-2" @click="getTasks"> <i class="el-icon-refresh"></i> </el-button>
            </el-col>
        </el-row>
        <draggable class="list-group" tag="ul" v-model="statData" v-bind="dragOptions" @start="drag = true" @end="drag = false">
            <transition-group type="transition" class="row my-2" :name="!drag ? 'flip-list' : null">
                <b-col v-for="stat in statData" :key="stat.title" class="mt-2 mt-xl-0" lg="4" xl="2" md="6">
                    <span @click="getItemValue(stat.id_status)" style="cursor: pointer;">
                        <widget-stat :title="stat.title" :image="stat.image" :subText="stat.subText" :value="stat.value" :color="stat.color" :backgroundMini="stat.backgroundMini"
                        :background="stat.background"/>
                    </span>
                </b-col>
            </transition-group>
        </draggable>  
        <el-table :data="tasks" v-loading="loadingAssing" class="mt-1" height="100%" style="max-height: 800px; min-height: 600px;" size="small" stripe header-cell-class-name="text-white" :header-cell-style="{'background-color' : '#308EEC', 'font-weight': 400}">
            <el-table-column prop="id" label="ID" width="60" class-name="text-center"/>
            <el-table-column :label="$t('delivery.delivery_courier')">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="`${scope.row.employee.first_name} ${scope.row.employee.last_name}`" placement="top" v-if="scope.row.employee">
                        <span class="text-capitalize text-limited mb-0 size-12">
                            {{ scope.row.employee.first_name.toLowerCase()}} {{scope.row.employee.last_name.toLowerCase()}}
                        </span>
                    </el-tooltip>
                    <el-tag v-else type="danger" size="mini">No empleado</el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('delivery.time')" class-name="text-center">
                <template slot-scope="scope">
                    <el-tag>{{$d(new Date(scope.row.created_at), 'alt_short')}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('delivery.customer')">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" :content="`${clearFiltersTask ? scope.row.route_detail.entity.first_name : scope.row.customer}`" placement="top"  v-if="clearFiltersTask ? scope.row.route_detail.entity.first_name : scope.row.customer">
                        <h6 class="text-capitalize text-limited mb-0 title-12">{{ clearFiltersTask ?  scope.row.route_detail.entity.first_name.toLowerCase() : scope.row.customer.toLowerCase()}}</h6>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column :label="$t('delivery.status')" width="120" class-name="text-center">
                <template slot-scope="scope">
                    <el-tag :color="scope.row.status.background_color" size="small" style="width: 100px;border: none;">
                        <span class="text-white">{{ scope.row.status.description }}</span>
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('tracking.filter_by_SLA')" width="100" class-name="text-center">
                <template slot-scope="scope">
                    <div :style="{background: scope.row.document.sla_background_color, color: scope.row.document.sla_text}" class="time-sla mx-2" :class="[`time-sla-${scope.row.document.sla_status_id}`, scope.row.document.follow_up_completed ? '' : 'animation']">
                        {{scope.row.document.sla_name}}
                    </div>
                    <!-- <el-tag v-if="scope.row.document.sla_name" >{{scope.row.document.sla_name}}</el-tag> -->
                </template>
            </el-table-column>
            <el-table-column :label="$t('delivery.time')" width="100" class-name="text-center">
                <template slot-scope="scope">
                    <span :id="`task-time-${scope.row.id}`" />
                </template>
            </el-table-column>
            <el-table-column :label="$t('delivery.incidents')" width="100" class-name="text-center">
                <template slot-scope="scope">
                    <el-tag type="danger" class="cursor-pointer" size="small" v-if="scope.row.incidents.length" @click.native="showTaskIncidents(scope.row)">
                        {{scope.row.incidents.length}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column v-if="!clearFiltersTask" prop="document_id" :label="$t('delivery.document')" width="120" class-name="text-center"/>
            <el-table-column prop="type.description" :label="$t('delivery.task_type')" width="120" class-name="text-center"/>
            <el-table-column prop="sales_location.description" :label="$t('delivery.branch')" class-name="text-limited"/>
            <el-table-column prop="method.description" label="Metodo" width="100" class-name="text-center"/>
            <el-table-column fixed="right" :label="$t('ecommerce.actions')" class-name="text-center">
                <template slot-scope="scope">
                    <el-tooltip class="item" effect="dark" content="Detalles" placement="top">
                        <el-button size="mini" type="primary" @click="selectTask(scope.row)" circle>
                            <i class="el-icon-view"></i>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                        <el-button size="mini" type="warning" @click="updateTask(scope.row)" circle>
                            <i class="el-icon-edit"></i>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Asignar o reasignar" placement="top">
                        <el-button size="mini" type="info" @click="reassign(scope.row)" circle>
                            <i class="el-icon-sort"></i>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="Ignorar validacion de la ubicacion" placement="top"  v-if="showIgnoreUbicationValidationButton">
                        <el-popconfirm title="Estas seguro que deseas realizar esta accion?" @confirm="toggleTaskIgnoreLocationValidationField(scope.row)">
                            <el-button :type="scope.row.ignore_location_validation ? 'danger': 'success'" size="mini"  class="ms-2" circle slot="reference">
                                <i class="el-icon-location-outline"></i>
                            </el-button>
                        </el-popconfirm>
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
        <div class="d-flex justify-content-end">
            <el-pagination background layout="prev, pager, next" :total="totalDocs" class="mt-2" :current-page="page" @current-change="changePage" :page-size="filters.limit" small/>
        </div>
        <task-details @closeModal="showDetails = false" v-if="showDetails" :taxIndexProp="taskDetailsDefaultTabIndex" />
        <update-task v-if="showUpdateTask" @closeModal="showUpdateTask = false" :task="updateTaskData"/>
        <reassing v-if="showReassing" @closeModal="showReassing = false" :task="selectedReassing" @refresh="getTasks"/>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import 'vue2-datepicker/index.css'; 
import Reassing from '../tasks/assign/Reassing.vue';
import { http } from "@/common/services/web-backend.js";
import TaskDetails from './modals/TaskDetails.vue';
import UpdateTask from './modals/UpdateTask.vue';
import Draggable from 'vuedraggable'
import WidgetStat from "../../../layout/components/widgets/widget-stat.vue";
import AppSelection from '../../../../common/components/selects/AppSelection.vue';
export default {
    name: "TaskHistory",
    props:{
        selectedRunTask:{
            type: [Number, String]
        },
        clearFiltersTask:{
            type: Boolean
        }
    },
    data() {
        return {
            filters: {
                employee_id: [],
                sales_location_id: [],
                order_by_type: 'desc',
                order_by: 'created_at',
                keywords: null,
                limit: 15,
                status: [1],
                has_incidences: 0,
                range: null,
                types: [],
                document_types: [],
                route_id: this.selectedRunTask
            },
            newStatus: [],
            page: 1,
            loadingAssing: false,
            showReassing: false,
            select: {},
            showDetails: false,
            tasksWithTime: [],
            interval: null,
            tasks: [],
            totalDocs: 0,
            moment: moment,
            showUpdateTask: false,
            updateTaskData: {},
            statData: [],
            drag: false,
            taskDetailsDefaultTabIndex: 0,
            showIgnoreUbicationValidationButton: false,
            debounceTimer: null,
        }
    },
    components:{
        Reassing,
        TaskDetails,
        UpdateTask,
        Draggable,
        WidgetStat,
        AppSelection
    },
    computed:{
        ...mapGetters({
            employees: "delivery/employees",
            stores: "ecommerce/stores",
            status: "delivery/status",
            types: "documentsFollowup/types",
            tasksTypes: "delivery/taskType",
        }),
        dragOptions() {
            return {
                animation: 500,
                group: "tasks",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        employeesOptions(){
            return this.employees.map(e=>{
                return {label: `[${e.entity.code}] ${e.entity.first_name}  ${e.entity.last_name}`, id: e.entity.code}
            });
        },
        storesOptions(){
            return this.stores.map(e=>{
                return {label: `[${e.codplace}] ${e.description}`, codplace: e.codplace}
            });
        },
        statusOptions(){
            return this.status.map(e=>{
                return {label: `[${e.id}] ${e.description}`, id: e.id}
            });
        },
        typesOptions(){
            return this.types.map(e=>{
                return {label: `[${e.id}] ${e.name}`, id: e.id}
            });
        },

        typesOptionsTask(){
            return this.tasksTypes.map(e=>{
                return {label: `[${e.id}] ${e.description}`, id: e.id}
            })
        }
    },
    methods:{
        async getTasks(){
            try {
                clearInterval(this.interval)
                this.loadingAssing = true
                let date = null;
                if(this.filters.range)
                date = [moment(this.filters.range[0]).format('YYYY-MM-DD'), moment(this.filters.range[1]).format('YYYY-MM-DD')]
                const filters = {
                    employee_code: this.filters.employee_id,
                    sales_location_id: this.filters.sales_location_id,
                    date: date,
                    order_by: this.filters.order_by,
                    order_by_type: this.filters.order_by_type,
                    q: this.filters.keywords ? this.filters.keywords : null,
                    status_id: this.filters.status,
                    limit : this.filters.limit,
                    page: this.page,
                    has_incidences: this.filters.has_incidences,
                    document_type: this.filters.document_types,
                    type_id: this.filters.types,
                    route_id: this.selectedRunTask
                }
                const response = await this.$store.dispatch('delivery/getAllTasks', filters)
                this.tasks = response.data
                this.totalDocs = response.total
                await this.getSummary(filters)
                this.statData[0].value = response.total
                response.data.forEach(task => { this.getTimeOfTask(task) });
                
                this.startTimer()
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    icon: 'error',
                    title: 'HA OCURRIDO UN ERROR OBTENIENDO LOS DATOS',
                    text: error.response?.data?.message,
                })
            }finally{
                this.loadingAssing = false
            }
        },
        async reassign(data){
            // !data.employee ||
            if( data.status_id > 2) return;

            this.selectedReassing = data;
            this.showReassing = true;
        },
        async deleteTask(code){
            this.$swal.fire({ 
                title: 'Confirmacion', text: "Realmente desea eliminar esta tarea?", 
                icon: "warning", showCancelButton: true, confirmButtonText: 'Confirma', 
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if(result.isConfirmed){
                    try {
                        await this.$store.dispatch('delivery/deleteTask', code)
                        await this.getTasks()
                        this.$swal.fire({ 
                            title: 'Tarea eliminada', text: "Tarea eliminada con exito", 
                            icon: "success",
                            timer: 2000,
                            showCancelButton: false
                        })
                    } catch (error) {
                        this.$swal.fire({ 
                            title: 'Error al eliminar', text: error.response.data.message, 
                            icon: "error",
                        })
                    }
                }
            })
        },
        async selectTask(item, defaultTabIndex = 0){
            try {
                this.loadingAssing = true;

                await this.$store.dispatch('delivery/getTaskDetails', item.id)
                await this.$store.dispatch("delivery/getTaskIncidents", {task_id: item.id});
                
                // set default tab index for taskDetails tabs component 
                this.taskDetailsDefaultTabIndex = defaultTabIndex
                this.showDetails = true

            } catch (error) {
                console.log(error);
            } finally{
                this.loadingAssing = false;
            }
        },
        showTaskIncidents(item) {
            if (item.incidents.length == 0) {
                return true;
            }

            this.selectTask(item, 4);
        },
        getTimeOfTask(data){
            const startDate = moment(data.created_at)
            const endDate = data.completed ? moment(data.date_completed).format() : moment()
            const dtDiff = moment(endDate).diff(startDate);

            //express as a duration
            const diffDuration = moment.duration(dtDiff);
            let result = moment().startOf("day");
            result.add(diffDuration.hours(), 'hours')
            result.add(diffDuration.minutes(), 'minutes')
            result.add(diffDuration.seconds(), 'seconds')
            const days = diffDuration.days()
            const findIndex = this.tasksWithTime.findIndex(item=> item.id == data.id)
            if(findIndex == -1)
            this.tasksWithTime.push({id: data.id, time: result, days: days, completed: data.completed})
        },
        startTimer(){
            clearInterval(this.interval)
            this.interval = setInterval(() => {
                this.tasksWithTime.forEach(task => {
                    if(!task.completed)
                    task.time.add(1, 'second');
                    const span = document.getElementById(`task-time-${task.id}`)
                    if(span){
                        span.innerHTML = `${task.days >= 1 ? `${task.days}D` : ''} ${task.time.format('HH:mm:ss')}`;
                    }
                });
            }, 1000)
        },
        getItemValue(item){
            this.filters.status = []
            if(item){
                this.filters.status = [item]
                this.newStatus = [item]
            } else { 
                this.filters.status = []
            }

        },
        async updateTask(task){
            if(task.status_id == 5){
                return
            }
            this.updateTaskData = task
            // console.log(task);
            this.showUpdateTask = true
        },
        async toggleTaskIgnoreLocationValidationField(item) {
            try {
                await http.patch(`/task/${item.id}/ignore_location_validation`, {
                    ignore_location_validation: !item.ignore_location_validation
                });
                this.$notify({
                    title: 'Guardado con exito',
                    message: 'Los cambios se han almacenado',
                    type: 'success'
                });                
                await this.getTasks();
            } catch(e) {
                this.$swal.fire({ 
                    title: 'Error al actualizar campo ignore_location_validation_field', text: e.response.data.message, 
                    icon: "error",
                });
            }
        },
        async getSummary(filters){
            try {
                const response = await http.get("task/getSummary", {params: filters})
                const statData = [
                    {   
                        title: "delivery.tasks",
                        image: require("@/common/assets/images/services-icon/01.png"),
                        value: this.totalDocs,
                        subText: 100,
                        color: "info",
                        backgroundMini: 'sent'
                    },
                ]
                const images = [
                    'https://cdn-icons-png.flaticon.com/128/1407/1407089.png',
                    'https://cdn-icons-png.flaticon.com/512/6314/6314804.png',
                    'https://cdn-icons-png.flaticon.com/512/4474/4474370.png',
                    require("@/common/assets/images/services-icon/04.png"),
                    require("@/common/assets/images/services-icon/04.png")
                ]
                response.data.data.forEach((state, index) => {
                    statData.push(
                        {
                            title: state.description,
                            value: state.total,
                            background: state.background_color,
                            subText: state.total != 0 ? parseFloat((state.total / this.totalDocs) * 100).toFixed(2) : 0,
                            image: images[index],
                            id_status: state.id_status
                        }
                    )
                });
                this.statData = statData
            } catch (error) {
                console.log(error);
            }
        },
        getNameCustomer(item){
            if(item.document.type_id == 3) return `${item.document.branch_name}→${item.customer}`
            else  return `${item.company_name.toUpperCase()} / ${item.customer.toUpperCase()}`
        },
        handleKeyDown(event) {
            // show the ignore validation secret button when pressing right shift on a button
            if (event.code == "ShiftRight") {
                this.showIgnoreUbicationValidationButton = !this.showIgnoreUbicationValidationButton;
            }
        },
        changePage(page){
            this.page = page;
        }
    },
    watch: {
        filters:{
            deep: true,
            async handler(val){
                this.page = 1

                if(val.keywords){
                    clearTimeout(this.debounceTimer);
                    this.debounceTimer = setTimeout( async() => {
                        await this.getTasks()
                    }, 500);
                    return;
                }

                await this.getTasks()
            }
        },
        page(){
            this.getTasks()
        },
        'clearFiltersTask'(){
            if(this.clearFiltersTask == true){
                this.filters.status = []
                this.filters.range = null
            }
        },
        'selectedRunTask'(){
            console.log('2');
            this.getTasks()
        },
        // 'newStatus'(){
        //     this.filters.status = this.newStatus
        //     this.page = 1
        // },
    },
    async created(){
        const dateMnsFive = moment(new Date).subtract(5, 'day');
        const date = new Date(dateMnsFive.toISOString());
        this.filters.range = [date, new Date()]

        if(this.employees.length == 0){
            await this.$store.dispatch('delivery/getTaskType');
            await this.$store.dispatch('delivery/getEmployees');
            await this.$store.dispatch('delivery/getTaskStatus');
        }
    },
    beforeDestroy(){
        clearInterval(this.interval)
    },
}
</script>

<style scoped>
    .mx-datepicker::v-deep{
        border: none;
        padding: 0;
        width: 100%;
    }
    .modal-mask.delivery-detail ::v-deep > .modal-container > .row > .map > .delivery-map{
        min-width: 500px;
    }
    .input-limit{
        height: 35px;
        width: 45px;
        padding: 4px;
        text-align: center;
        font-size: 12px;
        background: transparent;
        border: 0;
    }
    .time-sla{
        border-radius: 20px;
        text-align: center;
        max-width: 100px;
        margin: auto;
    }
    .time-sla-3.time-sla.animation{
        animation: blink 0.4s step-start infinite;
    }
    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
    .table-responsive::v-deep > .table > tbody > tr > td{
        padding: 4px;
        vertical-align: middle;
    }
    .table-responsive::v-deep > .table{
        text-align: center;
    }
    .cursor-no-drop{
        cursor:no-drop !important;
    }

    .time-sla{
        border-radius: 20px;
        text-align: center;
        color: #fff;
        /* max-width: 100px; */
        /* margin: auto; */
    }

    .hidden-secret-button {
        opacity: 0;
    }
</style>
